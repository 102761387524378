.d-flex {
	display: flex;
}

.d-block {
	display: block;
}

.d-none {
	display: none;
}

.absolute {
	position: absolute;
}

.relative {
	position: relative;
}

.abs-center-y {
	top: 50%;
	transform: translateY(-50%);
}

.abs-center-x {
	left: 50%;
	transform: translateX(-50%);
}

.abs-center {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.h-full {
	height: 100%;
}

.w-full {
	width: 100%;
}

.center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.center-y {
	display: flex;
	align-items: center;
}

.center-x {
	display: flex;
	justify-content: center;
}

.pointer {
	cursor: pointer;
}

.bottom-0 {
	bottom: 0;
}

.left-0 {
	left: 0;
}

.right-0 {
	right: 0;
}

.top-0 {
	top: 0;
}

.flex-col {
	flex-direction: column;
}

.flex-row {
	flex-direction: row;
}

.flex-row-reverse {
	flex-direction: row-reverse;
}

.justify-between {
	justify-content: space-between;
}

.justify-baseline {
	justify-content: baseline;
}

.justify-start {
	justify-content: flex-start;
}

.justify-end {
	justify-content: flex-end;
}

.justify-around {
	justify-content: space-around;
}

.justify-evenly {
	justify-content: space-evenly;
}

.justify-center {
	justify-content: space-evenly;
}

.items-center {
	align-items: center;
}

.items-start {
	align-items: flex-start;
}

.items-end {
	align-items: flex-end;
}

.fluid {
	width: 100%;
	height: 100%;
}

.flex-wrap {
	flex-wrap: wrap;
}

.whitespace-nowrap {
	white-space: nowrap;
}

.rotate-45 {
	transform: rotate(45deg);
}

.rotate--45 {
	transform: rotate(-45deg);
}

.rotate-180 {
	transform: rotate(180deg);
}
