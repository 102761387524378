.ant-select-dropdown {
	.ant-select-item-option-selected {
		background: #F7F7F9 !important;

		.ant-select-item-option-content {
			font-size: 14px !important;
			font-weight: normal !important;
		}
		.ant-select-item-option-state {
			svg {
			fill:#200E32;
			}
		}
	}
}

#chat-widget-container {
	transform: scale(0.9);
	right: -5px !important;
}

.cricket-sov-under-delivery {
	background-color: #FBEAEA;
	color: #C65353;
}

.cricket-sov-equal-delivery {
	background-color: #E9FBFA;
}

.cricket-sov-over-delivery {
	background-color: #FEFAE7;
}

.ant-dropdown-menu {
    max-height: 328px;
    overflow-y: auto;
}
